import { request } from "src/utils";

export const apiCreateOrder = (params: {
  subscription_id: number;
  price_id: string;
  pay_method: string;
  email: string;
}) =>
  request({
    url: "/open/v2/plan",
    method: "POST",
    data: {
      subscription_id: params.subscription_id,
      price_id: params.price_id,
      pay_method: params.pay_method,
      email: params.email,
    },
  });

export const apiCancelOrder = (params: { id: string }) =>
  request({
    url: `/open/v2/order?id=${params.id}`,
    method: "DELETE",
  });

export const apiGetOrderDetail = (params: { id: string }) =>
  request({
    url: "/open/v2/order",
    method: "GET",
    params: {
      id: params.id,
    },
  });

export const apiCreateKey = (params: { name: string; subscription_id: number }) =>
  request({
    url: "/open/v2/api_key",
    method: "POST",
    data: {
      subscription_id: params.subscription_id,
      name: params.name,
    },
  });

export const apiDeleteKeys = (params: { ids: number[] }) =>
  request({
    url: "/open/v2/api_key",
    method: "DELETE",
    data: params.ids,
  });

export const apiDeletePlan = (params: { id: number }) =>
  request({
    url: `/open/v2/plan?id=${params.id}`,
    method: "DELETE",
  });

export const apiRenameKey = (params: { id: number; name: string }) =>
  request({
    url: "/open/v2/api_key",
    method: "PUT",
    data: {
      id: params.id,
      name: params.name,
    },
  });

export const apiGetOrders = (params: { page: number; row: number }) =>
  request({
    url: "/open/v2/order",
    method: "GET",
    params: {
      row: params.row,
      page: params.page,
    },
  });

export const apiSendInvoice = (params: { email: string; order_id: string }) =>
  request({
    url: "/open/v2/order/invoice",
    method: "POST",
    data: {
      email: params.email,
      order_id: params.order_id,
    },
  });

export const apiPlanSubscribe = (params: { id: number; is_auto_renewal: boolean }) =>
  request({
    url: "/open/v2/plan",
    method: "PUT",
    data: {
      id: params.id,
      is_auto_renewal: params.is_auto_renewal,
    },
  });

export const apiOrderUnsubscribe = (params: { id: string }) =>
  request({
    url: `/open/v2/order?id=${params.id}`,
    method: "DELETE",
  });

export const apiGetKeyLogs = (params: { id: string; start_time: number; page: number; row: number }) =>
  request({
    url: "/open/v2/api_key/logs",
    method: "GET",
    params: {
      id: params.id,
      start_time: params.start_time,
      page: params.page,
      row: params.row,
    },
  });

export const apiGetPlans = () =>
  request({
    url: "/open/v2/plans",
    method: "GET",
  });

export const apiGetMyPlans = () =>
  request({
    url: "/open/v2/plans/my",
    method: "GET",
  });

export const apiGetPaymentMethodUrl = (params: {}) =>
  request({
    url: "/open/v1/user/payment/method",
    method: "PUT",
    data: {},
  });
